/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'envelope-check': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 00-2 2v8.01A2 2 0 002 14h5.5a.5.5 0 000-1H2a1 1 0 01-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 001 0V4a2 2 0 00-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 011-1h12a1 1 0 011 1v.217l-7 4.2z"/><path pid="1" d="M16 12.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0m-1.993-1.679a.5.5 0 00-.686.172l-1.17 1.95-.547-.547a.5.5 0 00-.708.708l.774.773a.75.75 0 001.174-.144l1.335-2.226a.5.5 0 00-.172-.686"/>',
    },
});
